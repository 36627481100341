<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenDeleteAccConfirm"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center"
          >
               <div
                    class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-xl max-h-full m-auto"
               >
                    <!-- Modal content -->
                    <div class="relative bg-shade-1 rounded-xl shadow-xl">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div
                              class="flex flex-col items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 font-bold text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div class="pb-4 w-full text-center">
                                   <h3
                                        class="text-base xs:text-lg font-bold text-gray-900"
                                   >
                                        Are you sure you want to delete this
                                        account?
                                   </h3>
                              </div>
                              <div class="w-full mt-5 mb-5">
                                   <label
                                        for="email"
                                        class="block text-base text-gray-200 font-light mb-3"
                                        >Please confirm your email
                                        address:</label
                                   >
                                   <input
                                        v-model="form.email"
                                        type="text"
                                        name="email"
                                        id="email"
                                        class="bg-white border text-shade-2 text-sm rounded-md block w-full p-2.5 font-normal"
                                        placeholder="Email"
                                        @change="clearFormError('email')"
                                        :class="
                                             form.errors?.errors?.email != null
                                                  ? 'border-primal-red ring-primal-red focus:ring-primal-red focus:border-primal-red'
                                                  : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                                        "
                                   />
                                   <has-error-form
                                        v-model:form="form"
                                        :field="'email'"
                                        class="font-normal"
                                   ></has-error-form>
                              </div>
                              <div
                                   class="pt-2 xs:pt-4 text-center w-full font-helveticaLight"
                              >
                                   <div class="flex w-full gap-4">
                                        <button
                                             @click="closeModal()"
                                             class="w-full text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                        >
                                             Cancel
                                        </button>
                                        <button
                                             @click="() => onDelete()"
                                             class="w-full text-shade-1 bg-shade-17 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                        >
                                             Delete
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <loading-overlay :isLoading="loading" />
     <div
          v-if="isOpenDeleteAccConfirm"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.3)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref, reactive } from "vue";
import eventBus from "plugins/event.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { utilitiesStore } from "store/utilities.js";
import Form from "vform";
import HasErrorForm from "components/haserror/index.vue";
import { deleteAccount as deleteAccountAPI } from "api/auth.js";
import { authStore } from "store/auth.js";
import { useToaster } from "composables/use-toaster.js";
import { useRouter } from "vue-router";
import { baseUrl } from "config/services.js";

export default {
     props: {
          onClickDelete: {
               type: Function,
               required: true
          },
          email: {
               type: String,
               required: true
          }
     },
     components: {
          HasErrorForm,
          LoadingOverlay
     },
     setup(props) {
          const isOpenDeleteAccConfirm = ref(false);
          const loading = ref(false);
          const useUtilitiesStore = utilitiesStore();
          const useAuthStore = authStore();
          const { successToast, errorToast } = useToaster();
          const router = useRouter();

          const form = reactive(
               new Form({
                    email: ""
               })
          );

          const toggleCreditConfirmation = () => {
               isOpenDeleteAccConfirm.value = !isOpenDeleteAccConfirm.value;
          };

          const closeModal = () => {
               isOpenDeleteAccConfirm.value = false;
               useUtilitiesStore.enableScroll();
               clearFormError();
          };

          const onDelete = async () => {
               if (props.email != form.email) {
                    if (form.email == "") {
                         form.errors.errors = {
                              email: "Please Input your Email Address!"
                         };
                    } else {
                         form.errors.errors = {
                              email: "Invalid Email Address!"
                         };
                    }
                    return;
               }

               loading.value = true;
               const params = {
                    email: form.email
               };

               await deleteAccountAPI(params)
                    .then(async () => {
                         loading.value = false;
                         successToast(
                              "Account Successfully Deleted!",
                              "top-right"
                         );
                         closeModal();
                         useAuthStore.logout().then(() => {
                              router.push({ path: "/signin" });
                         });
                    })
                    .catch(() => {
                         loading.value = false;
                         errorToast(
                              "Failed to delete your account",
                              "top-right"
                         );
                    });
          };

          const clearFormError = () => {
               form.errors.errors = {};
          };

          onMounted(async () => {
               eventBus.on("open:creditdeleteconfirmation", () => {
                    useUtilitiesStore.disableScroll();
                    toggleCreditConfirmation();
               });
               eventBus.on("close:creditdeleteconfirmation", () => {
                    useUtilitiesStore.enableScroll();
                    toggleCreditConfirmation();
               });
          });

          onUnmounted(() => {
               eventBus.off("open:creditdeleteconfirmation");
               eventBus.off("close:creditdeleteconfirmation");
          });

          return {
               loading,
               isOpenDeleteAccConfirm,
               closeModal,
               form,
               onDelete,
               clearFormError,
               baseUrl
          };
     }
};
</script>
