import { defineStore } from "pinia";
import { getCoins as getCoinsAPI } from "api/utility.js";
import {
     getWithdraw as getWithdrawAPI,
     worldpayStatus as worldpayStatusAPI
} from "api/transaction.js";
import { limitReach as limitReachAPI } from "api/auth.js";

export const coinsStore = defineStore("coins", {
     state: () => {
          return {
               coins: null,
               redeemable: null,
               error_msg: null,
               worldpayStatus: null,
               limitReachValue: null
          };
     },
     actions: {
          getWorldPay() {
               if (this.worldpayStatus != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    worldpayStatusAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.worldpayStatus = response.status;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.worldpayStatus = false;
                                   }
                              });
                              // console.error('Error to load coins data!', error);
                              // errorToast('Error to load data!', "top-right");
                              reject(error);
                         });
               });
          },
          getCoins() {
               if (this.coins != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getCoinsAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.coins = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.coins = error.response.data;
                                   }
                              });
                              // console.error('Error to load coins data!', error);
                              // errorToast('Error to load data!', "top-right");
                              reject(error);
                         });
               });
          },
          balanceRedeemable() {
               return new Promise((resolve, reject) => {
                    getWithdrawAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.redeemable = response.redeemedable;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.redeemable = 0;
                                   }
                              });
                              // console.error('Error to load coins data!', error);
                              // errorToast('Error to load data!', "top-right");
                              reject(error);
                         });
               });
          },
          getLimitReach(force = false) {
               if (!force) {
                    if (this.limitReachValue != null) {
                         return;
                    }
               }
               return new Promise((resolve, reject) => {
                    limitReachAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response.status) {
                                        state.limitReachValue = Number(
                                             response.purchase_amount ?? 0
                                        );
                                   } else {
                                        state.limitReachValue = null;
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   state.limitReachValue = null;
                              });
                              reject(error);
                         });
               });
          }
     }
});
