<template>
     <FilterGame
          :onSearch="onSearch"
          :onClear="onClear"
          :isDropdown="isDropdown"
          :onDropdown="
               (val) => {
                    isDropdown = val != null ? val : !isDropdown;
               }
          "
          :selectedDropdown="selectedDropdown"
          :onSelect="onSelect"
          :hideDropdown="true"
          :params="params"
          :sortByOpen="sortByOpen"
          :onSortByOpen="
               (val) => {
                    sortByOpen = val != null ? val : !sortByOpen;
               }
          "
          :onSort="onSort"
     />
     <CategoryGame
          v-if="search == ''"
          :onSetActive="onSetActive"
          :active="active"
     />
     <Recent
          v-if="isUser && ['recent', null].includes(active) && search == ''"
          :type="active == 'recent' ? 'paginate' : 'slider'"
          :onSetActive="onSetActive"
     />
     <!-- <Exclusive/> -->
     <Top
          v-if="active == null && search == ''"
          :onSetActive="onSetActive"
     />
     <Favorite
          v-if="isUser && ['favorite', null].includes(active) && search == ''"
          :type="active == 'favorite' ? 'paginate' : 'slider'"
          :onSetActive="onSetActive"
     />
     <New
          v-if="['new', null].includes(active) && search == ''"
          :type="active == 'new' ? 'paginate' : 'slider'"
          :onSetActive="onSetActive"
     />
     <div v-if="search == ''">
          <Custom
               v-for="(item, key) in groupData"
               :key="key"
               :game_type="item['game_type'].replaceAll(' ', '-')"
               :type="
                    active == item['game_type'].replaceAll(' ', '-')
                         ? 'paginate'
                         : active == null
                           ? 'slider'
                           : ''
               "
               :onSetActive="onSetActive"
          />
     </div>
     <All
          v-if="['all', null].includes(active) && search == ''"
          :onSetActive="onSetActive"
          :isLobby="active == null"
     />
     <Result
          v-if="search != ''"
          :search="search"
     />
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { gamesStore } from "store/games";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { useRoute, useRouter } from "vue-router";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";
import { utilitiesStore } from "store/utilities.js";
import FilterGame from "./filter/index.vue";
import CategoryGame from "./category/index.vue";
import Recent from "./list/recent/index.vue";
import Favorite from "./list/favorite/index.vue";
import New from "./list/new/index.vue";
// import Exclusive from "./list/exclusive/index.vue";
import Top from "./list/top/index.vue";
import All from "./list/all/index.vue";
import Custom from "./list/custom/index.vue";
import Result from "./list/result/index.vue";

export default {
     components: {
          FilterGame,
          CategoryGame,
          Recent,
          Favorite,
          Top,
          All,
          Custom,
          Result,
          New
     },
     setup() {
          const { money } = useFormatter();
          const route = useRoute();
          const router = useRouter();
          const { scrollTop } = useScroll();

          const banner = ref(null);

          const isDropdown = ref(false);

          const search = ref("");

          const params = reactive({
               page: 1,
               itemsPerPage: 20,
               game_type: [],
               game_promo: 0,
               game_name: "",
               provider_id: [],
               sortType: "release_date"
          });

          // const games = reactive({});

          const selfExclusion = ref(null);

          const selectedDropdown = ref([]);

          // all
          const active = ref(null);

          const all_count = ref(0);

          const loading = ref(false);

          const sortByOpen = ref(false);

          const useAuthStore = authStore();
          const useGamesStore = gamesStore();
          const useUtilitiesStore = utilitiesStore();

          let timer,
               timeoutVal = 1000;

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               active.value = useGamesStore.gameFilter["active"];

               return useGamesStore.gameFilter;
          });

          const groupData = computed(() => {
               if (useGamesStore.groups) {
                    if (route.path.split("/")[2] == "type") {
                         const filter = _.filter(
                              useGamesStore.groups,
                              (params) => {
                                   return (
                                        params["game_type"].toLowerCase() ==
                                        route.path.split("/")[3]
                                   );
                              }
                         );
                         if (filter.length != 0) {
                              active.value = filter[0]["game_type"].replaceAll(
                                   " ",
                                   "-"
                              );
                              params.game_type = [filter[0]["game_type"]];
                         }
                    }
               }
               return useGamesStore.groups || null;
          });

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const errorImages = ref([]);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const onImageError = (e) => {
               let temp = errorImages.value;
               temp.push(parseInt(e.target.alt));

               const uniq = _.uniq(temp);

               errorImages.value = uniq;

               e.target.src = "assets/img/games/default.png";
          };

          const onSetActive = async (data) => {
               if (data == "top") {
                    return;
               }
               active.value = data;
               if (data != null) {
                    router.replace({ query: { type: data } });
               } else {
                    router.replace();
               }
               window.scrollTo({
                    top: 0, // Scroll to the top
                    behavior: "smooth" // Smooth scroll animation
               });
          };

          const onSearch = (query) => {
               window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
               timer = window.setTimeout(async () => {
                    if (query.toLowerCase() == "") {
                         search.value = "";
                         useGamesStore.clearResult();
                    }

                    search.value = query.toLowerCase();

                    const temp = {
                         ...params,
                         game_name: query.toLowerCase(),
                         game_promo: 0,
                         game_type: [],
                         page: 1,
                         itemsPerPage: 21
                    };

                    await useGamesStore.getGames(temp, "result", true, true);
               }, timeoutVal);
          };

          const onClear = () => {
               search.value = "";
               useGamesStore.clearResult();
          };

          const onSelect = async (id) => {
               let data = selectedDropdown.value;

               if (id == 0) {
                    if (Object.keys(data).length == 0) {
                         data = _.pluck(providerData.value, "id");
                         selectedDropdown.value = data;
                         params.provider_id = data;
                    } else {
                         if (
                              Object.keys(data).length !=
                              providerData.value.length
                         ) {
                              data = _.pluck(providerData.value, "id");
                              selectedDropdown.value = data;
                              params.provider_id = data;
                         } else {
                              selectedDropdown.value = [];
                              params.provider_id = [];
                         }
                    }
               } else {
                    if (data.includes(id)) {
                         if (data.includes(0)) {
                              data.splice(data.indexOf(0), 1);
                         }
                         data.splice(data.indexOf(id), 1);
                         selectedDropdown.value = data;
                         params.provider_id = data;
                    } else {
                         data.push(id);
                         if (data.length == providerData.value.length) {
                              selectedDropdown.value = [0, ...data];
                         } else {
                              selectedDropdown.value = data;
                         }
                         params.provider_id = data;
                    }
               }

               params.page = 1;
               useGamesStore.setGameFilter(params, active.value);

               if (search.value != "") {
                    const temp = {
                         ...params,
                         game_name: search.value.toLowerCase(),
                         game_promo: 0,
                         game_type: [],
                         page: 1,
                         itemsPerPage: 21
                    };

                    useGamesStore.getGames(
                         temp,
                         "result",
                         true,
                         false,
                         "dropdown"
                    );

                    return;
               }

               useGamesStore.getPopular({
                    page: 1,
                    itemsPerPage: 21,
                    sortType: params.sortType
               });

               if (isUser.value) {
                    const temp = {
                         ...params,
                         game_type: [],
                         itemsPerPage: 21
                    };

                    useGamesStore.getRecent(temp, true, false, "dropdown");
                    useGamesStore.getFavorites(temp, true, false, "dropdown");
               }

               const temp = {
                    ...params,
                    game_type: [],
                    itemsPerPage: 48
               };

               useGamesStore.getGames(temp, "all", true, false, "dropdown");

               const newTemp = {
                    ...params,
                    game_type: [],
                    game_new: 1,
                    recommended: 1,
                    itemsPerPage: 21
               };

               useGamesStore.getGames(newTemp, "new", true, false, "dropdown");

               if (groupData.value) {
                    for (
                         let index = 0;
                         index < groupData.value.length;
                         index++
                    ) {
                         const gameType = groupData.value[index][
                              "game_type"
                         ].replaceAll(" ", "-");
                         const temp = {
                              ...params,
                              game_type: [groupData.value[index]["game_type"]],
                              itemsPerPage: 21
                         };
                         useGamesStore.getGames(
                              temp,
                              gameType,
                              true,
                              false,
                              "dropdown"
                         );
                    }
               }
          };

          const onSort = async (value) => {
               params.sortType = value;
               params.page = 1;

               useGamesStore.setGameFilter(params, active.value);

               sortByOpen.value = false;

               if (search.value != "") {
                    const temp = {
                         ...params,
                         game_name: search.value.toLowerCase(),
                         game_promo: 0,
                         game_type: [],
                         page: 1,
                         itemsPerPage: 21
                    };

                    useGamesStore.getGames(temp, "result", true, false, "sort");

                    return;
               }

               useGamesStore.getPopular({
                    page: 1,
                    itemsPerPage: 21,
                    sortType: value
               });

               if (isUser.value) {
                    const temp = {
                         ...params,
                         game_type: [],
                         itemsPerPage: 21
                    };

                    useGamesStore.getRecent(temp, true, false, "sort");
                    useGamesStore.getFavorites(temp, true, false, "sort");
               }

               const temp = {
                    ...params,
                    game_type: [],
                    itemsPerPage: 48
               };

               useGamesStore.getGames(temp, "all", true, false, "sort");

               const newTemp = {
                    ...params,
                    game_type: [],
                    game_new: 1,
                    recommended: 1,
                    itemsPerPage: 21
               };

               useGamesStore.getGames(newTemp, "new", true, false, "sort");

               if (groupData.value) {
                    for (
                         let index = 0;
                         index < groupData.value.length;
                         index++
                    ) {
                         const gameType = groupData.value[index][
                              "game_type"
                         ].replaceAll(" ", "-");
                         const temp = {
                              ...params,
                              game_type: [groupData.value[index]["game_type"]],
                              itemsPerPage: 21
                         };
                         useGamesStore.getGames(
                              temp,
                              gameType,
                              true,
                              false,
                              "sort"
                         );
                    }
               }
          };

          const openLogin = () => {
               router.push({ name: "login" });
          };

          const gotoPromotion = (item) => {
               router.push({
                    path:
                         "/promotion/detail/" +
                         item.title.replaceAll(" ", "-") +
                         "-" +
                         item.title_highlight.replaceAll(" ", "-") +
                         "-" +
                         item.id
               });
          };

          onMounted(async () => {
               if (!preloading.value) {
                    loading.value = true;
               }

               scrollTop();

               await useGamesStore.getProvider();
               await useGamesStore.getGameType();

               const path = route.fullPath.split("?");
               const query = path[1]?.split("&");

               const provider = _.find(query, function (params) {
                    return params.includes("provider");
               });

               if (provider) {
                    const split = provider.split("=");
                    const name = split[1].toLowerCase();

                    let filter = [];

                    if (name != "all") {
                         filter = _.filter(providerData.value, (value) => {
                              return value["name"].toLowerCase() == name;
                         });
                    }

                    if (filter.length != 0) {
                         params.provider_id = [filter[0]["id"]];
                         selectedDropdown.value = params.provider_id;
                    } else {
                         params.provider_id = _.pluck(providerData.value, "id");
                         selectedDropdown.value = params.provider_id;
                    }
               } else {
                    params.provider_id = _.pluck(providerData.value, "id");
                    selectedDropdown.value = params.provider_id;
               }

               const type = _.find(query, function (params) {
                    return params.includes("type");
               });

               if (type) {
                    const split = type.split("=");
                    const pluck = _.pluck(groupData.value, "game_type");
                    const map = _.map(pluck, function (params) {
                         return params.replaceAll(" ", "-");
                    });

                    if (isUser.value) {
                         const list = [
                              "new",
                              "favorite",
                              "recent",
                              "all",
                              ...map
                         ];

                         const find = _.find(list, function (params) {
                              return params == split[1].toLowerCase();
                         });

                         if (find) {
                              active.value = split[1].toLowerCase();
                         }
                    } else {
                         const list = ["new", "all", ...map];

                         const find = _.find(list, function (params) {
                              return params == split[1].toLowerCase();
                         });

                         if (find) {
                              active.value = split[1].toLowerCase();
                         }
                    }
               }

               useGamesStore.setGameFilter(params, active.value);

               if (isUser.value) {
                    const temp = {
                         ...params,
                         game_type: []
                    };

                    await useGamesStore.getRecent(temp);
                    await useGamesStore.getFavorites(temp);
               }

               const temp = {
                    ...params,
                    game_type: [],
                    itemsPerPage: 48
               };

               await useGamesStore.getGames(temp, "all");

               const newTemp = {
                    ...params,
                    game_type: [],
                    game_new: 1,
                    recommended: 1
               };

               await useGamesStore.getGames(newTemp, "new");

               if (groupData.value) {
                    for (
                         let index = 0;
                         index < groupData.value.length;
                         index++
                    ) {
                         const gameType = groupData.value[index][
                              "game_type"
                         ].replaceAll(" ", "-");
                         const temp = {
                              ...params,
                              game_type: [groupData.value[index]["game_type"]]
                         };
                         await useGamesStore.getGames(temp, gameType);
                    }
               }
          });

          watch(groupData, (newVal) => {
               if (newVal) {
                    if (route.path.split("/")[2] == "type") {
                         const filter = _.filter(newVal, (params) => {
                              return (
                                   params["game_type"].toLowerCase() ==
                                   route.path.split("/")[3]
                              );
                         });
                         if (filter.length != 0) {
                              active.value = filter[0]["game_type"].replaceAll(
                                   " ",
                                   "-"
                              );
                              params.game_type = [filter[0]["game_type"]];
                         }
                    }
               }
          });

          watch(gameFilter, (newVal) => {
               if (newVal) {
                    const obj = Object.keys(newVal);
                    for (let index = 0; index < obj.length; index++) {
                         params[obj[index]] = newVal[obj[index]];
                    }

                    active.value = newVal["active"];
               }
          });

          return {
               isDropdown,
               active,
               onSetActive,
               params,
               onImageError,
               isUser,
               onSearch,
               errorImages,
               selectedDropdown,
               onSelect,
               openLogin,
               all_count,
               money,
               loading,
               banner,
               selfExclusion,
               sortByOpen,
               onSort,
               baseUrl,
               gotoPromotion,
               groupData,
               search,
               onClear,
               providerData
          };
     }
};
</script>
