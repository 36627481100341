/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";

import "../assets/css/app.css";
import "../assets/css/fonticon.css";
import "../assets/css/icon.css";
import "../assets/css/livechat.css";
import "../assets/css/style.css";

import "vue3-carousel/dist/carousel.css";
import "vue-skeletor/dist/vue-skeletor.css";
import "vue-toastification/dist/index.css";
// import 'vue-tel-input/vue-tel-input.css';

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createPinia } from "pinia";

import AppRoute from "router";
import router from "./router";

import { HasError, AlertError } from "vform/src/components/tailwind";
import { Skeletor } from "vue-skeletor";
import Toast from "vue-toastification";

import VueTelInput from "vue-tel-input";
import VueLazyload from "vue-lazyload";
import { baseUrl } from "config/services.js";

import * as Sentry from "@sentry/vue";

import { TooltipDirective, TooltipComponent } from "vue3-tooltip";
import "vue3-tooltip/tooltip.css";

// import { BrowserTracing } from '@sentry/tracing';
// import { Inertia } from '@inertiajs/inertia';

createInertiaApp({
     title: (title) => `${title} - Moozi`,
     resolve: (name) => {
          return resolvePageComponent(
               `./app/${name}.vue`,
               import.meta.glob("./app/**/*.vue")
          );
     },
     setup({ el, App, props, plugin }) {
          const app = createApp({ render: () => h(App, props) });

          app.use(plugin)
               .use(AppRoute)
               .use(createPinia())
               .use(Toast)
               .use(VueTelInput)
               .use(VueLazyload, {
                    preLoad: 1.3,
                    error: baseUrl.img + "/assets/img/games/default.png",
                    attempt: 1
               })
               .directive("tooltip", TooltipDirective)
               .component("tooltip", TooltipComponent)
               .component(HasError.name, HasError)
               .component(AlertError.name, AlertError)
               .component(Skeletor.name, Skeletor);

          Sentry.init({
               app: app,
               environment: import.meta.env.VITE_ENV,
               dsn: import.meta.env.VITE_SENTRY_VUE_DSN, // Replace with your actual DSN
               enabled:
                    import.meta.env.VITE_ENV === "production" ||
                    import.meta.env.VITE_ENV === "staging",
               integrations: [
                    Sentry.browserTracingIntegration({ router }),
                    Sentry.replayIntegration(),
                    Sentry.browserProfilingIntegration()
               ],
               tracesSampleRate: 1.0,
               tracePropagationTargets: [
                    "localhost",
                    /^https:\/\/yourserver\.io\/api/
               ],
               replaysSessionSampleRate: 0.1,
               replaysOnErrorSampleRate: 1.0,
               profilesSampleRate: 1.0,
               beforeSend(event) {
                    const environment = import.meta.env.VITE_ENV;
                    if (
                         environment != "staging" ||
                         environment != "production"
                    ) {
                         return null;
                    }
                    return event;
               }
          });

          // }

          app.mount(el);
     },
     progress: {
          color: "#4B5563"
     }
});
