<template>
     <!-- Main modal -->
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenBankWallet"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-hidden overflow-x-hidden fixed z-100 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center m-auto flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-2xl max-h-full tracking-wider m-auto"
               >
                    <!-- Modal content -->
                    <div
                         class="relative bg-white rounded-lg shadow"
                         style="min-height: 50vh"
                    >
                         <div
                              id="sdk-container-bank"
                              style="max-height: 80vh"
                         ></div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenBankWallet"
          class="fixed inset-0 z-70"
          style="background: rgba(0, 0, 0, 0.75)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";

export default {
     props: ["onSubmit", "setLoading"],
     setup() {
          const useUtilitiesStore = utilitiesStore();
          const isOpenBankWallet = ref(false);

          const closeModal = () => {
               isOpenBankWallet.value = false;
               useUtilitiesStore.enableScroll();
          };

          onMounted(async () => {
               eventBus.on("open:bank_wallet", () => {
                    useUtilitiesStore.disableScroll();
                    isOpenBankWallet.value = true;
               });

               eventBus.on("close:bank_wallet", () => {
                    useUtilitiesStore.enableScroll();
                    isOpenBankWallet.value = false;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:bank_wallet");
               eventBus.off("close:bank_wallet");
          });

          return {
               isOpenBankWallet,
               closeModal
          };
     }
};
</script>
