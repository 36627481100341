<template>
     <div class="mb-7 lg:mb-3">
          <div
               v-if="groupData != null && !loading"
               class="game-tab-wrapper flex flex-row gap-2 overflow-x-auto overflow-y-hidden h-11 lg:h-13 pb-3 mx-2"
          >
               <div
                    v-if="active != null"
                    key="0"
                    :class="
                         active != null ? 'w-max opacity-100' : 'w-0 opacity-0'
                    "
                    class="transition-all ease-in-out duration-300"
               >
                    <div
                         @click="() => onSetActive(null)"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid border-moozi-4"
                    >
                         <span class="icon-allgames text-xl lg:text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              Lobby
                         </p>
                    </div>
               </div>
               <!-- <div key="0">
                    <div @click="() => onSetActive('hot')"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="active == 'hot' ? 'active border-transparent' : 'border-moozi-4'">
                         <span class="icon-hot text-xl lg:text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap">
                              Hot Games</p>
                    </div>
               </div> -->
               <div key="1">
                    <div
                         @click="() => onSetActive('new')"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="
                              active == 'new'
                                   ? 'active border-transparent'
                                   : 'border-moozi-4'
                         "
                    >
                         <span class="icon-hot text-xl lg:text-2xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              New
                         </p>
                    </div>
               </div>
               <div
                    v-if="isUser"
                    key="2"
               >
                    <div
                         @click="() => onSetActive('favorite')"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="
                              active == 'favorite'
                                   ? 'active border-transparent'
                                   : 'border-moozi-4'
                         "
                    >
                         <span
                              class="icon-chevron-fav text-xl lg:text-3xl"
                         ></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              Favorite
                         </p>
                    </div>
               </div>
               <div
                    v-if="isUser"
                    key="3"
               >
                    <div
                         @click="() => onSetActive('recent')"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="
                              active == 'recent'
                                   ? 'active border-transparent'
                                   : 'border-moozi-4'
                         "
                    >
                         <span
                              class="icon-chevron-recent text-xl lg:text-3xl"
                         ></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              Recent
                         </p>
                    </div>
               </div>
               <div key="4">
                    <div
                         @click="() => onSetActive('all')"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="
                              active == 'all'
                                   ? 'active border-transparent'
                                   : 'border-moozi-4'
                         "
                    >
                         <span class="icon-allgames text-xl lg:text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              All Games
                         </p>
                    </div>
               </div>
               <div
                    v-for="(item, key) in groupData"
                    :key="key + 5"
               >
                    <div
                         @click="
                              () =>
                                   onSetActive(
                                        cleanGameType(item.game_type)
                                   )
                         "
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="
                              active == cleanGameType(item.game_type)
                                   ? 'active border-transparent'
                                   : 'border-moozi-4'
                         "
                    >
                         <span
                              class="text-xl lg:text-2xl"
                              :class="
                                   'icon-' + cleanGameType(item.game_type)
                              "
                         ></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              <!-- {{ item.game_type }} -->
                              {{ cleanGameType(item.game_type) }}
                         </p>
                    </div>
               </div>
          </div>
          <div
               v-else
               class="game-tab-wrapper flex flex-row gap-2 overflow-x-auto overflow-y-hidden h-11 lg:h-13 pb-3 mx-2"
          >
               <div
                    v-for="key in 12"
                    :key="key"
               >
                    <Skeletor
                         as="div"
                         class="!w-32 lg:!w-44 !h-8 lg:!h-9 !rounded-full"
                    />
               </div>
          </div>
     </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import { authStore } from "store/auth.js";
import { gamesStore } from "store/games";

export default {
     props: ["onSetActive", "active"],
     setup() {
          const useAuthStore = authStore();
          const useGamesStore = gamesStore();

          const loading = ref(false);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const groupData = computed(() => {
               return useGamesStore.groups || null;
          });

          watch(groupData, () => {
               loading.value = true;
               setTimeout(() => {
                    loading.value = false;
               }, 1500);
          });

          const cleanGameType = (gameType) => {
               // Remove square brackets and quotes, and replace spaces with hyphens
               return gameType.replace(/[\[\]"]/g, '').replaceAll(' ', '-');
          };

          return {
               isUser,
               loading,
               groupData,
               cleanGameType
          };
     }
};
</script>